.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF8F0;
    position: absolute;
    top: 0;
    left: 0;
}

.container {
    position: relative;
    height: 100%;
}
