.mainContainer {
    padding: 0 10%;
}

.descriptionContainer {
    padding: 2% 0;
    display: flex;
    justify-content: center;
}

.mapContainer {
    padding: 2% 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.textContainer {
    display: flex;
    justify-content: center;
    color: #3D3D3D
}

.imageContainer {
    display: flex;
    justify-content: center;
}

.title {
    padding: 2% 0;
    text-align: center;
    font-weight: bold;
}

.map {
    height: 70vh;
    max-height: 800px;
    width: 100%;
}

@media (min-width: 992px) {
    .image {
        overflow: hidden;
        object-fit: contain;
        max-height: 400px;
    }
}

@media (max-width: 992px) {
    .image {
        overflow: hidden;
        max-height: 300px;
        object-fit: contain;
    }
}